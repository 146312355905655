import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/rent',
    name: 'rent',
    component: () => import('../views/WantRent.vue'),
    meta: {
      title: '租房'
    }
  },
  {
    path: '/ride',
    name: 'ride',
    component: () => import('../views/WantRide.vue'),
    meta: {
      title: '搭车'
    }
  },
  {
    path: '/airport',
    name: 'airport',
    component: () => import('../views/AirportTrans.vue'),
    meta: {
      title: '机场接送'
    }
  },
  {
    path: '/post',
    name: 'post',
    component: () => import('../views/PostView.vue'),
    meta: {
      title: '发布消息'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterView.vue'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/mymessage',
    name: 'mymessage',
    component: () => import('../views/MyMessage.vue'),
    meta: {
      title: '我的消息'
    }
  },
  {
    path: '/mypost',
    name: 'mypost',
    component: () => import('../views/MyPost.vue'),
    meta: {
      title: '我的发布'
    }
  },
  {
    path: '/rentResult',
    name: 'rentResult',
    component: () => import('../views/RentResult.vue'),
    meta: {
      title: '查找结果'
    }
  },
  {
    path: '/messenger',
    name: 'messenger',
    component: () => import('../views/MessengerView.vue'),
    meta: {
      title: '消息中心'
    }
  },
  {
    path: '/viewPictures',
    name: 'viewPictures',
    component: () => import('../views/ViewPictures.vue'),
    meta: {
      title: '查看图片'
    }
  },
  {
    path: '/rideResult',
    name: 'rideResult',
    component: () => import('../views/RideResult.vue'),
    meta: {
      title: '搭车'
    }
  },
  {
    path: '/airportResults',
    name: 'airportResults',
    component: () => import('../views/AirportResult.vue'),
    meta: {
      title: '机场接送'
    }
  },
  {
    path: '/bringResults',
    name: 'bringResults',
    component: () => import('../views/BringResults.vue'),
    meta: {
      title: '机场接送'
    }
  },
  {
    path: '/coachResults',
    name: 'coachResults',
    component: () => import('../views/CoachResults.vue'),
    meta: {
      title: '机场接送'
    }
  },
  {
    path: '/moveResults',
    name: 'moveResults',
    component: () => import('../views/MoveResults.vue'),
    meta: {
      title: '机场接送'
    }
  },
  {
    path: '/exchangeResults',
    name: 'exchangeResults',
    component: () => import('../views/ExchangeResults.vue'),
    meta: {
      title: '机场接送'
    }
  },
  {
    path: '/seconduseResults',
    name: 'seconduseResults',
    component: () => import('../views/SeconduseResults.vue'),
    meta: {
      title: '机场接送'
    }
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import('../views/ArticleMain.vue'),
    meta: {
      title: '机场接送'
    }
  },
  {
    path: '/article',
    name: 'article',
    component: () => import('../views/OneArticle.vue'),
    meta: {
      title: '机场接送'
    }
  },
  {
    path: '/manageAccount',
    name: 'manageAccount',
    component: () => import('../views/ManageAccount.vue'),
    meta: {
      title: '机场接送'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(),
  routes
})

export default router
